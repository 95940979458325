import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FooterProps } from 'components/footer/footer.props';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'ui-kit/link/link';
import Logo from 'ui-kit/logo/logo';

import './footer.style.scss';

const Footer = ({ copyrightYear, paths, supportPaths, socialMediaPaths, legalPaths }: FooterProps) => {
    const { t } = useTranslation();
    return (
        <footer>
            <Container fluid="xl">
                <Row>
                    <Col xs={12} md={12} lg={2}>
                        {paths && paths.length > 0 && (
                            <>
                                <div className="footer-col-title">{t('menus.footer.main.title')}</div>
                                <div className="footer-col-content">
                                    {paths.map(({ link, label }) => (
                                        <Link
                                            key={`footer-path-${label}`}
                                            className="footer-content-item"
                                            to={link?.url}
                                            label={label}
                                            variant="footer"
                                            dataGALocation="FooterNav"
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={12} lg={2}>
                        {supportPaths && supportPaths.length > 0 && (
                            <>
                                <div className="footer-col-title">{t('menus.footer.support.title')}</div>
                                <div className="footer-col-content">
                                    {supportPaths.map(({ link, label }) => (
                                        <Link
                                            key={`footer-support-path-${label}`}
                                            className="footer-content-item"
                                            to={link?.url}
                                            label={label}
                                            variant="footer"
                                            dataGALocation="FooterNav"
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={12} lg={2}>
                        {socialMediaPaths && socialMediaPaths.length > 0 && (
                            <>
                                <div className="footer-col-title">{t('menus.footer.app.title')}</div>
                                <div className="footer-col-content">
                                    {socialMediaPaths.map(({ link, label }) => (
                                        <Link
                                            key={`footer-socialMedia-path-${label}`}
                                            className="footer-content-item"
                                            to={link?.url}
                                            label={label}
                                            variant="footer"
                                            dataGALocation="FooterNav"
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col
                        xs={{ span: 12, order: 'first' }}
                        md={{ span: 12, order: 'first' }}
                        lg={{ span: 6, order: 'last' }}
                        className="logo-col d-flex justify-content-start justify-content-lg-end"
                    >
                        <Logo variant="primary" />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex flex-column align-items-start flex-md-row mt-5">
                        <div className="footer-copyright">{t('menus.footer.legal.title', { year: copyrightYear })}</div>
                        <>
                            {legalPaths.map(({ link, label }) => (
                                <Link
                                    key={`footer-path-${label}`}
                                    className="mt-4 mt-md-0"
                                    to={link?.url}
                                    label={label}
                                    variant="footer-legal"
                                    dataGALocation="FooterNav"
                                />
                            ))}
                        </>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
