import React, { ReactElement, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Row, Col, Container } from 'react-bootstrap';

import { AlertProps } from './alert-banner.props';

import './alert-banner.style.scss';
import { Link } from 'gatsby';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import StarIcon from 'ui-kit/icons/star/star-icon';

const dismissBanner = () => {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('dismissPermanentBanner', 'true');
    }
};

const hasBeenDismissed = () => {
    if (typeof sessionStorage !== 'undefined') {
        const hasBeenDismissed = sessionStorage.getItem('dismissPermanentBanner') != null ? true : false;
        return hasBeenDismissed;
    }
};

const AlertBanner = ({ message, link, linkLocation, variant, permanent, icon }: AlertProps): ReactElement => {
    const [show, setShow] = useState(true);

    let renderIcon = null;
    switch (icon) {
        case 'alert': {
            renderIcon = <AlertIcon />;
            break;
        }
        case 'star': {
            renderIcon = <StarIcon />;
            break;
        }
    }

    if (show && (permanent ? !hasBeenDismissed() : true)) {
        return (
            <Alert
                className="alert-banner mb-0"
                onClose={() => {
                    setShow(false);
                    if (permanent) {
                        dismissBanner();
                    }
                }}
                variant={variant}
                dismissible
                data-ga-location="Alert Banner"
                data-ga-type={variant}
            >
                <Container>
                    <Row>
                        <Col
                            xs={{ span: 8, offset: 2 }}
                            sm={{ span: 8, offset: 2 }}
                            md={{ span: 12, offset: 0 }}
                            lg="12"
                        >
                            <p className="alert-message">
                                {renderIcon}
                                {message}
                                {link && (
                                    <>
                                        {' '}
                                        <Link to={linkLocation} className="alert-link">
                                            {link}
                                        </Link>
                                    </>
                                )}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Alert>
        );
    }
    return <></>;
};

export default AlertBanner;
