import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import withSecurity from 'hoc/withSecurity';
import { InitOptimize } from 'util/google_optimize/optimize_helper';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import { accountFetchProfileRoutine } from 'state/account/account.routines';

import Navigation from 'components/navigation/navigation.component';
import Footer from 'components/footer/footer.component';

import AlertBanner from 'components/alert-banner/alert-banner.component';
import { PageLayoutProps } from 'components/layouts/page/page.props';

import BackgroundImage from 'ui-kit/background-image/background-image';
import PageSection from 'ui-kit/page-section/page-section';
import SEO from 'components/seo/seo.component';

import './page.layout.scss';
import withSessionExpiration from 'hoc/withSessionExpiration';
import BirdiModal from 'components/birdi-modal/birdi-modal';

const PageLayout = ({
    children,
    headerImage,
    headerImageStyle,
    headerImageClassName,
    isSecurePage,
    isLoggedIn,
    pageContentClassName,
    metaData
}: PageLayoutProps) => {
    const data = AlertBannerAndMenusQuery();
    const dispatch = useDispatch();
    const [profileFetched, setProfileFetched] = useState(false);
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);

    const pageClasses = classNames('page');
    const pageContentClasses = classNames('page-content', pageContentClassName);

    const paths = data.allMenuLinkContentMain.nodes;

    const footerPaths = data.allMenuLinkContentFooter.nodes;

    const filteredPaths = paths.filter((navItem: { requiresAccountAuth: boolean }) =>
        isSecurePage || isLoggedIn ? navItem.requiresAccountAuth : !navItem.requiresAccountAuth
    );

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path.alias.toString()
    );

    useEffect(() => {
        if (isLoggedIn && !profileFetched) {
            setProfileFetched(true);
            dispatch(accountFetchProfileRoutine.trigger());
        }
    }, [profileFetched]);
    useEffect(() => {
        InitOptimize();
    }, []);

    return (
        <div className={pageClasses}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                children={children}
            />
            {data.allBlockContentAlertBanner.nodes[0] && (
                <AlertBanner
                    message={data.allBlockContentAlertBanner.nodes[0].field_alert_text}
                    link={data.allBlockContentAlertBanner.nodes[0].field_alert_link?.title}
                    linkLocation={data.allBlockContentAlertBanner.nodes[0].field_alert_link?.uri}
                    variant={
                        data.allBlockContentAlertBanner.nodes[0].field_alert_priority_level
                            ? data.allBlockContentAlertBanner.nodes[0].field_alert_priority_level
                            : 'dark'
                    }
                    permanent={
                        data.allBlockContentAlertBanner.nodes[0].field_alert_permanent
                            ? data.allBlockContentAlertBanner.nodes[0].field_alert_permanent
                            : true
                    }
                />
            )}
            <Navigation paths={filteredPaths} isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} />
            {headerImage && (
                <BackgroundImage image={headerImage} style={headerImageStyle} className={headerImageClassName} />
            )}
            <div className={pageContentClasses}>
                {children}
                {applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                    (paragraph: any, index: number) => (
                        <PageSection key={`application_page_section_${index}`}>
                            {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                        </PageSection>
                    )
                )}
            </div>
            <BirdiModal />
            <Footer
                copyrightYear={new Date().getFullYear().toString()}
                paths={isLoggedIn ? filteredPaths : footerPaths}
                socialMediaPaths={data.allMenuLinkContentFooterSocial.nodes}
                supportPaths={data.allMenuLinkContentFooterSupport.nodes}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes}
            />
        </div>
    );
};

export default withSessionExpiration(withSecurity(PageLayout));

export const AlertBannerAndMenusQuery = () => {
    const AlertBannerAndMenus = useStaticQuery<any>(graphql`
        {
            allBlockContentAlertBanner(
                sort: { fields: changed, order: DESC }
                filter: { field_alert_active: { eq: true } }
            ) {
                nodes {
                    field_alert_text
                    field_alert_priority_level
                    field_alert_permanent
                    field_alert_link {
                        title
                        uri: url
                    }
                }
            }
            allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    isButton: field_menu_cta
                    link {
                        url
                    }
                    label: title
                    requiresAccountAuth: field_requires_account_auth
                    langcode
                }
            }
            allMenuLinkContentFooter(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterSocial(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterSupport(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                }
            }
        }
    `);
    return AlertBannerAndMenus;
};
