import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PageSectionProps } from './page-section.props';

import './page-section.style.scss';

const PageSection = ({ className, children, style, index }: PageSectionProps): ReactElement => {
    const classes = classNames('page-section', className);
    return (
        <Container fluid={'xl'} className={classes} style={style ?? {}}>
            <Row>
                <Col>{children}</Col>
            </Row>
        </Container>
    );
};

export default PageSection;
